@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
  }
  
  #page-wrap {
    padding-bottom: 10px;
    padding-top: 10px;
  }